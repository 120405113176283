$(function() {
    "use strict";
    $(function() {
        $(".preloader").fadeOut();
    }), window.onbeforeunload = function() {
        $(".preloader").fadeIn();
    }, jQuery(document).on("click", ".mega-dropdown", function(e) {
        e.stopPropagation();
    });
    var set = function() {
        (window.innerWidth > 0 ? window.innerWidth : this.screen.width) <= 1280 ? ($("body").addClass("mini-sidebar"),
        $(".navbar-brand span").hide(), $(".sidebartoggler i").addClass("ti-menu"), $('.sidebartoggler').addClass('active')) :
        ($("body").removeClass("mini-sidebar"), $(".navbar-brand span").show(), $('.sidebartoggler').removeClass('active'));
        var height = (window.innerHeight > 0 ? window.innerHeight : this.screen.height) - 1;
        (height -= 0) < 1 && (height = 1), height > 0 && $(".page-wrapper").css("min-height", height + "px");
    };
    $(window).ready(set), $(window).on("resize", set), $(".sidebartoggler").on("click", function() {
        $("body").hasClass("mini-sidebar") ? ($("body").trigger("resize"), $("body").removeClass("mini-sidebar"),
        $(".navbar-brand span").show()) : ($("body").trigger("resize"), $("body").addClass("mini-sidebar"),
        $(".navbar-brand span").hide());$('body').hasClass('mini-sidebar') ? $('.sidebartoggler').addClass('active') :
        $('.sidebartoggler').removeClass('active')
    }), $(".nav-toggler").click(function() {
        $("body").toggleClass("show-sidebar"), $(".nav-toggler i").toggleClass("ti-menu"),
        $(".nav-toggler i").addClass("ti-close");
    }), $(".search-box a, .search-box .app-search .srh-btn").on("click", function() {
        $(".app-search").toggle(200);
    }), $(".right-side-toggle").click(function() {
        $(".right-sidebar").slideDown(50), $(".right-sidebar").toggleClass("shw-rside");
    }), $(".floating-labels .form-control").on("focus blur", function(e) {
        $(this).parents(".form-group").toggleClass("focused", "focus" === e.type || this.value.length > 0);
    }).trigger("blur"), $(function() {
        var link = window.location.pathname

        for (var url = link, element = $("ul#sidebarnav a[href]").filter(function() {
            if(url.indexOf(this.pathname) != -1){
                $(this).addClass("active").parents('li').last().addClass("active")
            }
        });;) {
            if (!element.is("li")) break;
        }
    }), $(function() {
        for (var url = window.location, element = $(".topheader .navbar a").filter(function() {
            if(url.indexOf(this.pathname) != -1){
                $(this).addClass("active").parents('li').last().addClass("active")
            }
        });;) {
            if (!element.is("li")) break;
        }
    }), $(".scroll-sidebar, .right-side-panel, .message-center, .right-sidebar").perfectScrollbar(),
    $("body").trigger("resize"), $(".list-task li label").click(function() {
        $(this).toggleClass("task-done");
    }), $('a[data-action="collapse"]').on("click", function(e) {
        e.preventDefault(), $(this).closest(".card").find('[data-action="collapse"] i').toggleClass("ti-minus ti-plus"),
        $(this).closest(".card").children(".card-body").collapse("toggle");
    }), $('a[data-action="expand"]').on("click", function(e) {
        e.preventDefault(), $(this).closest(".card").find('[data-action="expand"] i').toggleClass("mdi-arrow-expand mdi-arrow-compress"),
        $(this).closest(".card").toggleClass("card-fullscreen");
    }), $('a[data-action="close"]').on("click", function() {
        $(this).closest(".card").removeClass().slideUp("fast");
    });
    var setResponsive = function() {
        if ((window.innerWidth > 0 ? window.innerWidth : this.screen.width) <= 1024) {
            $('.stats-box h6').eq(0).text("Emails Sent");$('.stats-box h6').eq(1).text("Emails Opened");$('.stats-box h6').eq(2).text("Phish Clicks");
        }
    };
    $(window).ready(setResponsive)
});
